// _themes.scss
.body-light {
    --background-color: #1D2125;
    --text-color: #ffffff;
    --component-color: #161A1D;
    --text-contrast-color: #1b1b1b;
    --contrast-color: rgb(215 168 107);
    --contrast-color-hover: rgb(255, 186, 97);
}

.body-dark {
    --background-color: #eeeeee;
    --text-color: #565752;
    --component-color: #565752;
    --text-contrast-color: #1b1b1b;
    --contrast-color: rgb(215 168 107);
    --contrast-color-hover: rgb(255, 186, 97);
}
