@import '../../../styles/variables';

.myaccount .main_panel h6 {
    margin-top: 20px;
    text-align: left;
    color: $text-color;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 133%;
    margin-bottom: 0;
}
.myaccount .main_panel h2{
    color: $text-color;
}

.btn_edit {
    float: right;
    color: $text-contrast-color !important;
    text-align: center;
    text-transform: none !important;
    font-family: 'Lora' !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    background-color: $contrast-color !important;
    display: flex;
    width: 120px !important;
    height: 50px !important;
    padding: 0px 32px;
    border-radius: 100px !important;
}

.btn_edit:hover {
    transition: ease-in-out .2s;
    background-color: $contrast-color-hover !important;
    color: $text-contrast-color !important;
}

.btns_form_myaccount {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
}

.btn_cancel {
    color: $text-color;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    border: solid 1px white;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 120px !important;
    height: 50px;
    padding: 0 32px !important;
    border-radius: 100px;
}

.btn_cancel:hover {
    transition: ease-in-out .2s;
    background-color: $contrast-color-hover !important;
    color: $text-contrast-color !important;
}

/* Mobiles */
@media (min-width: 300px) and (max-width: 425px) {
    .myaccount .main_panel {
        width: auto !important;
    }

    .myaccount h2 {
        font-size: 25px !important;
    }

    .myaccount .main_panel {
        margin-top: 30px;
        padding-bottom: 60px;
    }

    .myaccount .btn_edit,
    .btns_form_myaccount a {
        margin-top: 20px;
    }

}

/* Tablet */
@media (min-width: 426px) and (max-width: 768px) {
    .myaccount .main_panel {
      width: auto;
    }
}

/* Laptop */
@media (min-width: 769px) and (max-width: 1024px) {

    .myaccount .main_panel {
        margin-top: 100px !important;
        margin-left: auto;
        margin-right: auto;
        width: 665px;
    }
}