@import '../../../styles/variables';

.clients{
    margin-top: 0px;
}

.clients .paper_top {
    color: $text-color !important;
    background-color: transparent !important;
}

.clients .paper_top .MuiInputBase-root {
    background-color: $component-color !important;
    border: none !important;
    color: $text-color !important;
    font-weight: 400 !important;
}

.clients .paper_top .MuiInputLabel-root {
    color: $text-color !important;
    font-weight: 400 !important;
}

.clients .paper_table {
    background-color: $component-color !important;
}

.clients .paper_table tr th {
    font-weight: bold !important;
    color: $text-color !important;
    border-bottom: solid 1px rgb(77, 77, 77);
}

.clients .paper_table td {
    cursor: pointer;
    color: $text-color !important;
    border-bottom: solid 1px rgb(77, 77, 77);
}

.clients .paper_table tr:hover {
    background-color: $contrast-color;
    color: $text-contrast-color !important;
}