@import '../../../styles/variables';

.panel h1{
    font-size: 30px;
}

.icons_dashboard{
    color: $contrast-color;
    font-size: 35px !important;
    width: 40px !important;
}

.grid_panel{
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.second_row_panel{
    display: flex;
    flex-direction: row;
    gap: 24px;
}

.overview_panel{
    margin-top: 24px;
    padding: 20px;
    border: solid 1px white;
    border-radius: 8px;
}
.overview_panel h6{
    font-size: 17px;
}

.recents_panel{
    margin-top: 24px;
    padding: 20px;
    border: solid 1px white;
    border-radius: 8px;
    color: white !important;
}
.recents_panel h6{
    font-size: 17px;
}
