@import '../../../styles/variables';

.PasswordConfirmPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    text-align: center;
    background-color: $background-color;
    height: 100vh;
}