@import '../../../styles/variables';

.FinancePage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-color;
    text-align: center;
    height: auto;
}
