@import '../../styles/variables';

.recovery_form {
    margin: auto auto;
    display: flex;
    max-width: 517px;
    height: auto;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    color: #333333 !important;
    background: $background-form-login !important;
    box-shadow: $box-shadow-login !important;
}

.recovery_form .logo img{
    width: 200px;
}

.recovery_form .information {
    font-size: 16px;
    font-weight: 500;
    margin: auto;
}

.recovery_form  .verify_email{
    width: 300px;
}

.recovery_form .information p{
    color: var(--text-color);
    text-align: center;
    font-size: 15px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.box_recovery{
    margin-top: -20px !important;
}
.btn_return{
    text-decoration: none;
    margin-top: 10px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto;
    width: 100%;
    text-align: center;
    color: $contrast-color;
}


/* Mobiles */
@media (min-width: 300px) and (max-width: 425px){

    .recovery_form .button_login{
        margin-top: 20px;
    }
   
}