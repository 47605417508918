@import '../../styles/variables';

.login_form {
    margin: auto auto;
    display: flex;
    max-width: 517px;
    height: 300px;
    padding: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-radius: 8px;
    color: #333333 !important;
    background: $background-form-login !important;
    box-shadow: $box-shadow-login !important;
}

.login_form img {
    width: 200px;
}

.icon_visibility svg {
    color: rgba(199, 199, 199, 0.555) !important;
}

.forgot_password {
    margin-top: 10px;
    margin-bottom: 15px;
    text-decoration: none;
    float: right;
    text-align: right;
    color: $contrast-color !important;
    text-align: center;
    font-family: 'Lora' !important;
    font-size: 12px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 140%;
}

.forgot_password:hover {
    transition: ease-in-out .2s;
    color: var(--error-300);
}

.button_login {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px !important;
    width: 100% !important;
    height: 50px;
    border-radius: 100px !important;
    background: $contrast-color !important;
    color: rgb(49, 49, 49) !important;
    text-align: center;
    text-transform: none !important;
    font-family: 'Lora' !important;
    font-size: 16px !important;
    font-weight: 600 !important;
}

.button_login:hover {
    transition: ease-in-out .2s;
    background-color: $contrast-color !important;
}

.text_first_access {
    margin-top: 24px;
    margin-bottom: 28px;
    color: $text-color-login !important;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
}

.first_access {
    margin-top: 20px;
    text-decoration: none;
    border-radius: 100px;
    border: 1px solid #BFBFBF;
    padding: 15px 40px;
    color: $text-color-login !important;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
}

.first_access:hover {
    transition: ease-in-out .2s;
    background-color: #FFF;
    color: var(--primary-600);
}

.login_form .MuiInputBase-root {
    background-color: white !important;
    color: black !important;
}

.login_form .MuiInputLabel-root {
    color: black !important;
}

.login_form .MuiInputBase-root.Mui-focused {
    color: black !important;
}

.login_form .MuiInputBase-root label {
    color: black !important;
}

.login_form .MuiFilledInput-input:-webkit-autofill {
    background-color: white !important;
}

/* Mobiles */
@media (min-width: 300px) and (max-width: 425px) {

    .forgot_password {
        padding-top: 10px;
    }

}