@import '../../../styles/_variables.scss';

.toggle-switch {
    position: relative;
    //display: inline-block;
    display: none;
    width: 40px;
    height: 30px;
}

.theme-switch-checkbox {
    opacity: 0;
    width: 0;
    height: 0;
}

.theme-switch-label {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #7c7c7c94;
    transition: .4s;
    border-radius: 34px;
    display: flex;
    align-items: center;
}

.slider {
    height: 20px;
    width: 28px;
    transition: .4s;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}

.theme-switch-checkbox:checked + .theme-switch-label .slider {
    transform: translateX(15px);
}

.light {
    color: #ffffffa2;
}

.dark {
    color: $text-color;
}