@import '../../../styles/variables';

.LoginPage{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-color-login !important;
    background-image: $background-image-login !important;
    background-size: cover;
    background-position: center;
    text-align: center;
    height: 100vh;
}