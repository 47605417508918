/******* Arquivo principal que importa todos os outros ********/
// main.scss
@import 'variables';
@import 'mixins';
@import 'base';

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html, body {
  margin: 0;
  padding: 0;
  background-color: $background-color !important;
  color: $text-color;
  overflow-x: hidden;
  font-size: 16px;
  font-family: 'Lora';
  line-height: 1.6;
}

::-webkit-scrollbar {
  width: 8px; 
}
::-webkit-scrollbar-track {
  background: #636363;
}
::-webkit-scrollbar-thumb {
  background:#D7A86B!important; 
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #D7A86B !important; 
}

.pc{
  display: block !important;
}

.mobile{
  display: none !important;
}

/*** Component Date Picker ****/
.react-datepicker {
  font-family: "Poppins" !important;
  font-size: 0.8rem;
  background-color: $component-color !important;
  color: $text-color;
  border: 1px solid #585858 !important;
  border-radius: 0.3rem;
}
.react-datepicker__header{
  background-color: #1d2125 !important;
  border-bottom: 1px solid #585858 !important;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header{
  color: $text-color !important;
}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name{
  color: $text-color !important;
}

/*** Definition of Mui Input Base structure ****/
.MuiInputLabel-root {
  color: $text-color !important;
  font-family: 'Lora' !important;
  font-weight: 400 !important;
  font-size: 16px !important;
}

.MuiFilledInput-input:-webkit-autofill {
  border-radius: 8px !important;
  font-weight: bold !important;
  background-color: $component-color !important;
}

.MuiFilledInput-root::after {
  border: none !important;
}

.MuiFilledInput-root::before {
  border: none !important;
}

.MuiInputBase-root {
  font-family: 'Lora' !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  color: $text-color !important;
  border-radius: 8px !important;
  border: 1px solid rgb(58, 58, 58) !important;
  background-color: $component-color !important;
}

.MuiInputBase-root:hover {
  background: rgba(41, 41, 41, 0.08);
}

.MuiInputBase-root label {
  font-family: 'Lora' !important;
  color: rgb(0, 0, 0) !important;
  font-weight: 400 !important;
}

.MuiInputBase-root.Mui-focused {
  border: 1px solid rgb(215 168 107) !important;
  border-radius: 8px;
  color: $text-color;
  font-weight: 400 !important;
}

.MuiButton-root{
  background-color: $contrast-color !important;
  color: $text-contrast-color !important;
  margin-bottom: 10px !important;
  font-weight: 400 !important;
}
/*** Definition of Mui Paper ****/
.MuiDialog-paper{
  color: $text-color;
  background-color: $component-color !important;
}

/*** Definition Message ****/
.MuiSnackbarContent-message {
  font-family: 'Poppins' !important;
  font-size: 16x;
}

.snackbar-custom {
  position: fixed !important;
  top: 90% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
}

.blank .MuiSnackbarContent-root{
  width: 350px !important;
  background-color: $component-color !important;
  color: $text-color !important;
  border-bottom:solid 4px $contrast-color;
}

.success .MuiSnackbarContent-root{
  width: 350px !important;
  background-color: $component-color !important;
  color: $text-color !important;
  border-bottom:solid 4px #1dce6f;
}

.error .MuiSnackbarContent-root{
  width: auto !important;
  background-color: $component-color !important;
  color: $text-color !important;
  border-bottom:solid 4px #ce1d1d;
}

.circular_progress{
  color: white !important;
}
