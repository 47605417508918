@import './theme';

/******* Variáveis de cores, fontes, etc ********/

// _variables.scss
$primary-color: #000000;
$secondary-color: #ffffff;

// System Color General
$background-color: var(--background-color);
$text-color: var(--text-color);
$component-color: var(--component-color);
$text-contrast-color: var(--text-contrast-color);
$contrast-color: var(--contrast-color);
$contrast-color-hover: var(--contrast-color-hover);

// Page Login/Cadastro/Recuperação de senha
$background-color-login: var(--background-color);
$background-image-login: none;

// Component Login/Cadastro/Recuperação de senha
$background-form-login: var(--component-color);
$text-color-login: #ffffff;
$box-shadow-login: none;

// Page My Account - Dashboard
$background-color-myaccount: #1f1f1f;