@import '../../../styles/variables';

.MyAccountPage{
    padding-top: 90px;
    padding-bottom: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $background-color-myaccount;
    text-align: center;
    height: auto;
}

/* Laptop */
@media (min-width: 769px) and (max-width: 1024px) {
   
    .MyAccountPage{
        padding-top: 0px;
        padding-bottom: 0px;
        height: 100vh;
    }
}