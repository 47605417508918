@import '../../../styles/_variables.scss';

.logo_dash img {
    margin-top: 15px !important;
    display: block;
    margin: auto auto;
    width: 180px;
}

.menu_dash {
    margin-bottom: 20px;
}

.header .links_dash a {
    color: $text-color;
    text-decoration: none;
    border-radius: 8px;
}

.header .links_dash a:hover {
    transition: ease-in-out .2s;
    background-color: $contrast-color !important;
}

.active {
    background-color: $contrast-color;
    color: #3a3a39 !important;
}

.links_dash {
    margin-top: 40px;
    display: flex;
    gap: 7px;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}

.links_dash a {
    display: flex !important;
    font-weight: bold;
    gap: 10px;
    width: 90%;
    padding: 8px;
}

.links_dash a:hover {
    background: #fff;
    color: #3a3a39;
    border-radius: 8px;
}

.streaming p {
    margin: 0;
    padding: 0;
}

.streaming {
    color: #D9D9D9;
    display: flex !important;
    gap: 10px;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border-radius: 100px;
    border: 1px solid #FFF;
}

.streaming:hover {
    transition: ease-in-out .2s;
    background-color: #FFF;
    color: var(--primary-600) !important;
}

.profile{
    text-align: center;
}
.profile h3{
    color: $text-color;
    padding-top: 10px;
    margin: 0;
    margin-bottom: -10px;
    font-size: 18px;
}
.profile span{
    padding: 0;
    margin-top: -10px !important;
    color: $text-color;
    font-size: 10px;
    text-transform: uppercase;
}
.button_menu {
    margin: auto;
    height: 60px;
    width: 60px;
    cursor: pointer;
    background-color: $background-color;
    color: $text-color;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
}
.button_menu svg{
    font-size: 35px;
    font-weight: 400;
}

.button_profile{
    padding-top: 10px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 20px;
}
.button_profile svg{
    color: $text-color;
    font-size: 20px;
}

.manu_dash:hover {
    transition: ease-in-out .2s;
    background-color: #FFF;
    color: var(--primary-600);
}

.MuiList-root {
    padding: 0 !important;
}

.nav_menu_dash {
    margin-top: 20px;
    margin-left: 0px;
}

.nav_menu_dash li {
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav_menu_dash .MuiPaper-root {
    background-color: #141414 !important;
    border-radius: 8px !important;
    width: 140px !important;
    padding: 16px !important;
}

.nav_menu_dash a {
    font-family: 'Inter';
    font-size: 13px;
    font-weight: 600;
    color: #D9D9D9;
    text-decoration: none;
}

.nav_menu_dash a:hover {
    transition: ease-in-out .2s;
    color: #fff;
}

/* Mobiles */
@media (min-width: 300px) and (max-width: 768px) {

    .streaming {
        display: none !important;
    }

    .nav_menu_dash {
        margin-top: 20px;
        margin-left: 0px;
    }

    .nav_menu_dash li {
        padding-top: 7px;
        padding-bottom: 7px;
    }

    .nav_menu_dash a {
        padding: 0 !important;
        margin: 0 !important;
    }

    .MuiPaper-root {
        left: 108px !important;
    }

    .nav_menu_dash_mobile hr {
        margin-top: 10px;
        margin-bottom: 10px;
        border: solid 1px rgba(255, 255, 255, 0.08);
    }

    .MuiMenuItem {
        height: 20px !important;
    }

}