@import '../../styles/variables';

.confirm_form {
    margin: auto auto;
    display: flex;
    max-width: 517px;
    height: auto;
    padding: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    align-self: stretch;
    border-radius: 8px;
    background: var(--component-color);
}
