@import '../../../styles/variables';

.finance .paper_top {
    color: $text-color !important;
    background-color: transparent !important;
    box-shadow: none !important;
    margin-top: 0px;
}

.finance .paper_top .MuiInputBase-root {
    background-color: $component-color !important;
    border: none !important;
    color: $text-color !important;
    font-weight: 400 !important;
}

.finance .MuiFormControl-root svg{
    color: white !important;
}

.finance .paper_top .MuiInputLabel-root {
    color: $text-color !important;
    font-weight: 400 !important;
    border: none !important;
}

.finance .paper_table {
    background-color: $component-color !important;
}

.finance .paper_table tr th {
    font-weight: bold !important;
    color: $text-color !important;
    border-bottom: solid 1px rgb(77, 77, 77);
}

.finance .paper_table td {
    cursor: pointer;
    color: $text-color !important;
    border-bottom: solid 1px rgb(77, 77, 77);
}

.finance .paper_table tr:hover {
    background-color: $contrast-color;
    color: $text-contrast-color !important;
}

.total_bar {
    background-color: transparent !important;
    color: black;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    right: 0px;
    display: flex;
    gap: 15px;
    width: auto !important;
    position: fixed;
    top: 350px;
    font-weight: bold;
    font-size: 20px;
}

.total_value {
    text-align: center;
    font-family: 'Poppins';
    background-color: $contrast-color !important;
    width: auto !important;
    padding: 10px 30px 10px 10px;
    border-radius: 10px;
    margin-right: -18px;
}

.total_value_liquid {
    text-align: center;
    font-family: 'Poppins';
    background-color: $contrast-color !important;
    width: auto !important;
    padding: 10px 30px 10px 10px;
    border-radius: 10px;
    margin-right: -18px;
}

.total_value h3,
.total_value_liquid h3 {
    font-size: 20px;
    margin: 0;
    padding: 0;
}

.total_value:hover,
.total_value_liquid:hover {
    cursor: pointer;
    transition: ease-in-out 0.5s;
    margin-right: 0px;
}

.total_value p,
.total_value_liquid p {
    font-size: 20px;
    margin: 0;
    padding: 0;
    font-weight: 500;
}

/***** Parte referente ao POPUP do cliente ****/

.exit_client {
    color: white !important;
}

.content_client_base h2 {
    text-align: center;
}

.client_user {
    background-color: rgb(48, 48, 48);
    padding: 10px;
    border: solid 1px rgb(80, 80, 80);
    border-radius: 8px;
    font-family: 'Poppins';
}

.info_client_name {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}

.info_client_name h4 {
    margin: 0;
}

.client_user .icon_user {
    font-size: 40px;
}

.client_user p {
    margin: 0;
}

.info_client {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
}

.client_base .MuiDrawer-paper {
    z-index: 99 !important;
    width: 350px !important;
    background: #161A1D !important;
    padding: 20px;
}

.content_client_base {
    color: white !important;
}

.list_payments_client {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.row_list {
    background-color: rgb(48, 48, 48);
    padding: 10px;
    border: solid 1px rgb(80, 80, 80);
    border-radius: 8px;
    font-family: 'Poppins';
}

.list_payments_client .row_list p {
    font-size: 15px;
    margin: 0;
}

.status_received {
    background-color: #0080002e;
}
.status_pending {
    background-color: rgba(255, 230, 0, 0.253);
}
.status_cancelled {
    background-color: rgba(255, 255, 255, 0.158);
}
.status_refunded{
    background-color: #49adff44; 
}
.status_overdue{
    background-color: #fd200373; 
}
.MuiButtonBase-root .MuiButton-root .btn_import {
    padding: 25px !important;
}

.total_paid {
   text-align: right;
}